import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompanyReportsService } from '../../services/company-reports.service';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarEventSettings } from 'app/settings.class';
import { CalendarService } from 'app/calendar/calendar.service';

@Component({
  selector: 'con-report-dates',
  templateUrl: './report-dates.component.html',
  styleUrls: ['./report-dates.component.scss']
})

export class ReportDatesComponent implements OnInit, OnDestroy {

  @Input() entity: any = {};

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>();
  public refreshTable$: Subject<any> = new Subject<any>();

  public isLoading = false;
  public isSaving = false;
  public earningsTypeId: number;
  public tableFilter: any;
  public reportDatesPageError: string;
  public formErrors: string[] = [];
  public filterThreshold: {date: string, months: number};

  constructor( 
    public companyService: CompanyReportsService,
    private calendarService: CalendarService
  ) { }

  ngOnInit(): void {
    this.filterThreshold = { date: this.calendarService.getEarningsThresholdDate(), months: CalendarEventSettings.EARNINGS_EVENT_FILTER_THRESHOLD };
    this.initReportDatesPage();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private initReportDatesPage() {
    this.isLoading = true;
    this.companyService.getCalendarEventTypes().pipe(takeUntil(this.componentDestroyed$)).subscribe(
      (eventTypes: any) => {
        const calendarEventType = eventTypes.data.find((item) => item.name === CalendarEventSettings.REPORT_DATES_CALENDAR_EVENT_TYPE);
        if (calendarEventType) {
          this.earningsTypeId = calendarEventType.id;
        } else {
          this.reportDatesPageError = 'Earnings event type not found';
        }
        this.tableFilter = this.calendarService.getEarningsTableFilter(this.earningsTypeId, this.entity?.company?.id);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false
      })
  }

  public updateListing() {
    this.refreshTable$.next(true);
  }

  public getDisplayTitle() {
    return this.entity.company.name + ' / ' + this.entity.report_type + ', ' + moment(this.entity.report_date).format('yyyy-MM-DD');
  }
}