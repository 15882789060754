import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileService } from 'app/auth/services/profile.service';
import { EntitiesService, EntityDescription } from 'app/entities/services/entities.service';
import { ApiSettings, CalendarEventSettings } from 'app/settings.class';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private permissionConfig$ = new BehaviorSubject<any>(null);
  private earningSelectedCompany$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private earningsTypeId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private entitiesService: EntitiesService
  ) {
    this.getPermissionConfig().subscribe();
  }

  getDirektSyncList(params: HttpParams): Observable<any> {
    return this.http.get(`${ApiSettings.BASE_URL}/calendar_event/direkt_sync_status`, { params });
  }

  getPrefillValuesForReportDatesForm(entityDescription: EntityDescription): any {
    return {
      to_date: null,
      date_approximation_id: CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_approximation_id,
      time_approximation_id: CalendarEventSettings.REPORT_DATES_TIME_APPROXIMATION_ID,
      date_confirmed: entityDescription.getPrefillValueByFieldKey('date_confirmed') || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_confirmed,
      source_type: entityDescription.getPrefillValueByFieldKey('source_type') || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.source_type,
      profile_id: CalendarEventSettings.REPORT_DATES_DIREKT_PROFILE_ID,
      owner_type: CalendarEventSettings.REPORT_DATES_OWNER_TYPE
    }
  }

  getFlattendErrorArray(error: any): string[] {
    const messages: string[] = [];
    if (error.fields) {
      for (const field in error.fields) {
        if (error.fields.hasOwnProperty(field)) {
          messages.push(...error.fields[field]);
        }
      }
    }
    if (error.general) {
      messages.push(...error.general);
    }
    return messages;
  }
  
  getPermissionConfig(): Observable<any> {
    return this.http.get(`${ApiSettings.BASE_URL}/calendar_event/permission_hierarchy`).pipe(
      tap((response: any) => {
        this.permissionConfig$.next(response);
      })
    );
  }

  hasPermissionType(permissionString: string, permissionType: string): boolean {
    if (!permissionString) return false;
    switch (permissionType) {
      case 'read':
        return permissionString.includes('R');
      case 'write':
        return permissionString.includes('W');
      case 'edit':
        return permissionString.includes('E');
      default:
        return false;
    }
  }

  hasPermission(permissionType: 'write' | 'edit' | 'read', tiers: any): Observable<boolean> {
    return this.profileService.isSuperUser().pipe(
      switchMap(isSuperUser => {
        if (isSuperUser) {
          return of(true);
        }
        const teamId = this.profileService.getTeamId();
        if (!teamId) return of(false);
        return this.permissionConfig$.pipe(
          filter(permissionConfig => permissionConfig !== null),
          switchMap(permissionConfig => {
            const teamPermissions: any = Object.values(permissionConfig)
              .find((team: any) => +team.id === teamId);
            if (!teamPermissions) return of(false);

            const eventTierIds: any = tiers.map((tier: any) => tier.id.toString());
            const permissionTierIds: any = Object.values(permissionConfig.calendarTeam.tiers).map((tier: any) => tier.id);
            const tierIdsFiltered = eventTierIds.filter((id: any) =>
              permissionTierIds.includes(id)
            );
            if (tierIdsFiltered.length === 0) return of(false);

            const isOverlapping = tierIdsFiltered.length > 1 &&
              [permissionConfig.calendarTeam.tiers.dirSysExport.id, permissionConfig.calendarTeam.tiers.newsDeskCoverage.id]
                .every((tierId: any) => tierIdsFiltered.includes(tierId.toString()));

            const operator = teamId === +permissionConfig.calendarTeam.id ? 'every' : 'some';
            return of(tierIdsFiltered[operator]((tierId: any) => {
              const tierPermission: any = Object.values(teamPermissions.tiers).find((tier: any) => tier.id === tierId);
              if (!tierPermission) return false;

              let permissions;
              if (typeof tierPermission.permission === 'string') {
                permissions = tierPermission.permission;
              } else {
                permissions = isOverlapping ? tierPermission.permission.overlap : tierPermission.permission.nonOverlap;
              }

              return this.hasPermissionType(permissions, permissionType);
            }));
          })
        );
      })
    );
  }

  getEarningsTableFilter(eventTypeId: number, ownerId: number): any {
    return {
      from_date: `f:geq:${this.getEarningsThresholdDate()}`,
      owner_id: `f:eq:${ownerId}`,
      calendar_event_type_id: eventTypeId,
      owner_type: CalendarEventSettings.REPORT_DATES_OWNER_TYPE
    };
  }

  getEarningsThresholdDate(): string {
    const today = moment();
    return moment(today).subtract(CalendarEventSettings.EARNINGS_EVENT_FILTER_THRESHOLD, 'M').format('YYYY-MM-DD');
  }

  setEarningSelectedCompany(company: any): void {
    this.earningSelectedCompany$.next(company);
  }

  get getEarningSelectedCompany(): Observable<any> {
    return this.earningSelectedCompany$.asObservable();
  }

  setEarningsTypeId(id: number): void {
    this.earningsTypeId$.next(id);
  }

  get getEarningsTypeId(): Observable<number> {
    return this.earningsTypeId$.asObservable();
  }
}
